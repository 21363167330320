@import '../Core/variables.scss';

.#{$uikitName}__sidebar {
  display: block;
  height: 100%;
  width: 100%;
  padding: $spaceLg $spaceMd $spaceMd;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: $sidebarSize;
  padding-top: $headerHeight;
  height: 100%;
  background: $background-sidebar;
  border-right: 1px solid $sidebarBorderColor;
}
