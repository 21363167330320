@import '../Core/variables.scss';

.emankit__page_header {
  position: relative;
  padding: 20px 0 30px;

  &:before {
    content: "";
    top: -52px;
    position: absolute;
    width: 100vw;
    height: calc(100% + 52px);
    z-index: -1;
    left: 50%;
    margin-left: -50vw;
    background: $background-darkGrey;
  }

  &__content {
    max-width: 800px;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 5px;
  }

  &__description {
    font-size: 24px;
    color: $grey;
    margin: 0 0 30px;
  }

  @media (min-width: $mdBreakpoint) {
    &__content {
      margin-bottom: 30px;
    }
  }
}
