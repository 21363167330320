@import '../Core/variables.scss';

.emankit__notification_bar {
  box-shadow: 3px 7px 18px 0px rgba(0, 0, 0, 0.3);
  border: 1px solid $notificationBarBorderColor;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 200;

  .item {
    display: flex;
    line-height: 36px;
    user-select: none;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.error {
      background-color: $inputErrorBackgroundColor;
      color: $inputErrorBorderColor;
    }

    &:hover {
      background-color: $tableEvenRowColor;
    }

    &:nth-child(even) {
      background-color: $hoverCollapsibleColor;
    }

    &:nth-child(odd) {
      background-color: white;
    }

    .item_content {
      flex-grow: 1;
      padding-left: 20px;
    }

    .item_close {
      margin-left: 20px;
      padding-right: 10px;

      .emankit__icon {
        cursor: pointer;
      }

      .emankit__progress_indicator {
        height: 36px;
        text-align: right;
        .emankit__icon {
          width: 20px;
          height: 20px;
          margin-top: -2px;
          cursor: default;
        }
      }
    }
  }
}
