.#{$uikitName}__input_radio {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 10px;
  vertical-align: middle;
  margin-top: 2px;
  margin-bottom: 2px;

  &:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxyZWN0IG9wYWNpdHk9IjAuMSIgd2lkdGg9IjIzIiBoZWlnaHQ9IjIzIiB4PSIuNSIgeT0iLjUiIGZpbGw9IiMxMzI5M2QiIGZpbGwtcnVsZT0iZXZlbm9kZCIgcng9IjExLjUiIC8+PC9zdmc+);
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    content: "";
    display: block;
    overflow: hidden;
  }

  &:hover::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiCiAgICB2aWV3Qm94PSI0IDMgMjQgMjQiPgogICAgPGRlZnM+CiAgICAgICAgPHJlY3QgaWQ9ImIiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjEyIiAvPgogICAgICAgIDxmaWx0ZXIgaWQ9ImEiIHdpZHRoPSIxNTQuMiUiIGhlaWdodD0iMTU0LjIlIiB4PSItMjcuMSUiIHk9Ii0yMi45JSIgZmlsdGVyVW5pdHM9Im9iamVjdEJvdW5kaW5nQm94Ij4KICAgICAgICAgICAgPGZlT2Zmc2V0IGR5PSIxIiBpbj0iU291cmNlQWxwaGEiIHJlc3VsdD0ic2hhZG93T2Zmc2V0T3V0ZXIxIiAvPgogICAgICAgICAgICA8ZmVHYXVzc2lhbkJsdXIgaW49InNoYWRvd09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dCbHVyT3V0ZXIxIiBzdGREZXZpYXRpb249IjIiIC8+CiAgICAgICAgICAgIDxmZUNvbXBvc2l0ZSBpbj0ic2hhZG93Qmx1ck91dGVyMSIgaW4yPSJTb3VyY2VBbHBoYSIgb3BlcmF0b3I9Im91dCIgcmVzdWx0PSJzaGFkb3dCbHVyT3V0ZXIxIiAvPgogICAgICAgICAgICA8ZmVDb2xvck1hdHJpeCBpbj0ic2hhZG93Qmx1ck91dGVyMSIKICAgICAgICAgICAgICAgIHZhbHVlcz0iMCAwIDAgMCAwLjEyMTU2ODYyNyAwIDAgMCAwIDAuMjMxMzcyNTQ5IDAgMCAwIDAgMC41MDE5NjA3ODQgMCAwIDAgMC4wOCAwIiAvPgogICAgICAgIDwvZmlsdGVyPgogICAgPC9kZWZzPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJub256ZXJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0IDMpIj4KICAgICAgICA8dXNlIGZpbGw9IiMwMDAiIGZpbHRlcj0idXJsKCNhKSIgeGxpbms6aHJlZj0iI2IiIC8+CiAgICAgIDxyZWN0IG9wYWNpdHk9IjAuNSIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiB4PSIzIiB5PSIzIiBmaWxsPSIjMTMyOTNkIgogICAgICAgICAgICBzdHJva2UtbGluZWpvaW49InNxdWFyZSIgc3Ryb2tlLXdpZHRoPSI2IiByeD0iMTAiIC8+CiAgICAgICAgPHJlY3Qgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiB4PSIzIiB5PSIzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzEzMjkzZCIKICAgICAgICAgICAgc3Ryb2tlLWxpbmVqb2luPSJzcXVhcmUiIHN0cm9rZS13aWR0aD0iNiIgcng9IjEwIiAvPgogICAgPC9nPgo8L3N2Zz4=);
  }

  &:checked::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiCiAgICB2aWV3Qm94PSI0IDMgMjQgMjQiPgogICAgPGRlZnM+CiAgICAgICAgPHJlY3QgaWQ9ImIiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjEyIiAvPgogICAgICAgIDxmaWx0ZXIgaWQ9ImEiIHdpZHRoPSIxNTQuMiUiIGhlaWdodD0iMTU0LjIlIiB4PSItMjcuMSUiIHk9Ii0yMi45JSIgZmlsdGVyVW5pdHM9Im9iamVjdEJvdW5kaW5nQm94Ij4KICAgICAgICAgICAgPGZlT2Zmc2V0IGR5PSIxIiBpbj0iU291cmNlQWxwaGEiIHJlc3VsdD0ic2hhZG93T2Zmc2V0T3V0ZXIxIiAvPgogICAgICAgICAgICA8ZmVHYXVzc2lhbkJsdXIgaW49InNoYWRvd09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dCbHVyT3V0ZXIxIiBzdGREZXZpYXRpb249IjIiIC8+CiAgICAgICAgICAgIDxmZUNvbXBvc2l0ZSBpbj0ic2hhZG93Qmx1ck91dGVyMSIgaW4yPSJTb3VyY2VBbHBoYSIgb3BlcmF0b3I9Im91dCIgcmVzdWx0PSJzaGFkb3dCbHVyT3V0ZXIxIiAvPgogICAgICAgICAgICA8ZmVDb2xvck1hdHJpeCBpbj0ic2hhZG93Qmx1ck91dGVyMSIKICAgICAgICAgICAgICAgIHZhbHVlcz0iMCAwIDAgMCAwLjEyMTU2ODYyNyAwIDAgMCAwIDAuMjMxMzcyNTQ5IDAgMCAwIDAgMC41MDE5NjA3ODQgMCAwIDAgMC4wOCAwIiAvPgogICAgICAgIDwvZmlsdGVyPgogICAgPC9kZWZzPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJub256ZXJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0IDMpIj4KICAgICAgICA8dXNlIGZpbGw9IiMwMDAiIGZpbHRlcj0idXJsKCNhKSIgeGxpbms6aHJlZj0iI2IiIC8+CiAgICAgICAgPHJlY3Qgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiB4PSIzIiB5PSIzIiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzEzMjkzZCIKICAgICAgICAgICAgc3Ryb2tlLWxpbmVqb2luPSJzcXVhcmUiIHN0cm9rZS13aWR0aD0iNiIgcng9IjEwIiAvPgogICAgPC9nPgo8L3N2Zz4=);
  }
  &:checked:disabled::before {
    filter: grayscale(1);
  }
}

.#{$uikitName}__input_radio_group {
  label {
    cursor: pointer;
    user-select: none;
    margin-right: 16px;
    color: $black !important;
  }

  &.one-per-row {
    label {
      display: block;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  label:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}
