@import '../Core/variables.scss';

.emankit__color_ {
  &gray {
    color: $badgeGrayFontColor;
    border-color: $badgeGrayBorderColor;
    background-color: $badgeGrayColor;

    svg path {
      fill: $badgeGrayFontColor;
    }
  }

  &green {
    color: $badgeGreenFontColor;
    border-color: $badgeGreenBorderColor;
    background-color: $badgeGreenColor;

    svg path {
      fill: $badgeGreenFontColor;
    }
  }

  &orange {
    color: $badgeOrangeFontColor;
    border-color: $badgeOrangeBorderColor;
    background-color: $badgeOrangeColor;

    svg path {
      fill: $badgeOrangeFontColor;
    }
  }

  &red {
    color: $badgeRedFontColor;
    border-color: $badgeRedBorderColor;
    background-color: $badgeRedColor;

    svg path {
      fill: $badgeRedFontColor;
    }
  }

  &light_blue {
    color: $badgeLightBlueFontColor;
    border-color: $badgeLightBlueBorderColor;
    background-color: $badgeLightBlueColor;

    svg path {
      fill: $badgeLightBlueFontColor;
    }
  }

  &turquoise {
    color: $badgeTurquoiseFontColor;
    border-color: $badgeTurquoiseBorderColor;
    background-color: $badgeTurquoiseColor;

    svg path {
      fill: $badgeLightBlueFontColor;
    }
  }

}
