@import '../Core/variables.scss';

.emankit__info_table {
  background-color: $white;
  position: relative;
  width: 100%;
  margin: 0;

  th {
    text-align: left;
    padding: 0;
  }

  td:empty:before {
    content: '—';
  }

  thead th {
    color: $infoThColor;
    font-size: ($fontSize + 5px);
    font-weight: bold;
    text-align: left;
    padding: 0 0 16px;
  }

  tbody {
    th, td {
      padding: 0 0 12px;
      vertical-align: top;
    }

    th {
      color: $grey;
      font-weight: normal;
      width: 25%;
    }

    td {
      text-align: left;
      padding-left: 32px !important;
      color: $black;
    }

    tr:last-child {
      th, td {
        padding: 0;
      }
    }
  }

  @media (min-width: $lgBreakpoint) {
    tbody {
      th {
        width: 33.3%;
      }
    }
  }
}
