@import "../Core/variables";

.emankit__video {
  margin: 20px auto;

  &__wrapper {
    margin: auto;
    position: relative;
    width: 100%;
    max-width: 1000px;
    max-height: 80vh;
  }

  &__placeholder {
    width: 100%;
    padding-bottom: (100/16)*9%;
  }

  &__player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.react-player {
  &__preview {
    position: relative;
    background-color: $verylightblue;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: fade_out($verylightblue, 0.2);
    }
  }

  &__shadow {
    z-index: 2;
    border-radius: 80px !important;
    width: 80px !important;
    height: 80px !important;
    background: $cobalt !important;
  }

  &__play-icon {
    border-width: 12px 0 12px 16px !important;
  }
}
