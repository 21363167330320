@import '../Core/variables.scss';
@import '../Core/mixins.scss';

.#{$uikitName}__columns {
  border: 1px solid $grey;
  padding: 0 15px;
  border-radius: 4px;
  position: relative;

  &__container {
    @include calc-size(padding-top, $spaceMd, $spaceXl, $mdBreakpoint, $xlBreakpoint);
    @include calc-size(padding-bottom, $spaceMd, $spaceXl, $mdBreakpoint, $xlBreakpoint);
  }

  &__col {
    @include calc-size(padding-left, $spaceMd, $spaceXl, $mdBreakpoint, $xlBreakpoint);
    @include calc-size(padding-right, $spaceMd, $spaceXl, $mdBreakpoint, $xlBreakpoint);
  }

  @media (min-width: $mdBreakpoint) {
    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      border-right: 1px solid $grey;
    }

    &--cols_2 {
      &:before {
        left: 50%;
      }

      &:after {
        display: none;
      }
    }

    &--cols_3 {
      &:before {
        left: 33.3%;
      }

      &:after {
        left: 66.6%;
      }
    }
  }
}
