@import '../Core/variables.scss';

.emankit__page_section {
  position: relative;

  &--greyBg {
    &:after {
      border-top: none !important;
      height: 100%;
      background-color: $background-darkGrey;
      z-index: -1;
    }
  }

  &:not(:last-child):after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0px;
    margin-left: -50vw;
    width: 100vw;
    border-top: 1px solid $verylightblue;
  }

  &:nth-of-type(1) {
    padding-bottom: 20px;
  }

  &:not(:nth-of-type(1)) {
    padding: 20px 0;
  }

  @media (min-width: $mdBreakpoint) {
    &:nth-of-type(1) {
      padding-bottom: 32px;
    }

    &:not(:nth-of-type(1)) {
      padding: 32px 0;
    }
  }
}
