@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin calc-size($property, $min, $max, $start, $end) {
  $unit: unit($min);
  $formula: '';

  @if $unit == 'px' {
    $multiplier: strip-unit($max - $min) / strip-unit($end - $start) * 100;
    $adder: (strip-unit($min) * strip-unit($end) - strip-unit($max) * strip-unit($start)) / strip-unit($end - $start);
    $formula: #{($multiplier * 1vw)} + #{($adder * 1px)};
  } @else if $unit == 'rem' {
    $multiplier: (strip-unit($max - $min) * 10) / (strip-unit($end - $start) * 16) * 100;
    $adder: ((strip-unit($min) * 10 * strip-unit($end) * 16)  - (strip-unit($max) * 10 * strip-unit($start)* 16)) / (strip-unit($end - $start) * 16);
    $formula: #{($multiplier * 1vw)} + #{($adder * 0.1rem)};
  }

  $start: if(unit($start) == 'px', (strip-unit($start) / 16 * 1em), $start);
  $end: if(unit($end) == 'px', (strip-unit($end) / 16 * 1em), $end);

  #{$property}: $min;

  @media(min-width: $start) {
    #{$property}: calc(#{$formula});
  }

  @media(min-width: $end) {
    #{$property}: $max;
  }
}
