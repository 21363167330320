@import '../Core/variables.scss';

.respect__fieldset {
  position: relative;
  margin: 40px 0px 10px;
  padding: 0;
  border: none;

  legend {
    top: -25px;
    display: block;
    margin: 0 0 -10px 15px;
    margin-bottom: -10px;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: $fontFieldsetColor;
  }

}

