@import '../Core/variables.scss';
@import '../Core/mixins.scss';

$sidebarSize: 220px;

.#{$uikitName}__layout {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column;
  background-color: $background-layout;

  @include calc-size(padding-top, $headerHeight+$spaceMd, $headerHeight+$spaceLg, $mdBreakpoint, $xxlBreakpoint);
  @include calc-size(padding-left, $spaceMd, $spaceLg, $mdBreakpoint, $xxlBreakpoint);
  @include calc-size(padding-right, $spaceMd, $spaceLg, $mdBreakpoint, $xxlBreakpoint);
  @include calc-size(padding-bottom, $spaceLg, $spaceXl, $mdBreakpoint, $xxlBreakpoint);

  &__content {
    width: 100%;
    min-width: 600px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    &--hasSidebar {
      padding-left: $sidebarSize;
    }
  }
}
