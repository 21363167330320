@import '../Core/variables.scss';
@import './filterItem.scss';

$topMargin: 30px;

.emankit__filter_bar {
  flex-direction: row;
  margin-bottom: 16px;
  align-self: center;
  max-width: calc(100vw - 50px);
  min-width: 100%;

  display: none;
  @media (min-width: 1024px) {
    display: flex;
  }

  .checkbox_container {
    margin-top: 38px;
    line-height: 20px;

    label {
      line-height: 20px;
    }
  }

  & > div {
    &.without_label {
      padding-top: $topMargin;
    }

    &.item {
      margin-right: 24px;
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
      }
    }

    &.active {
      input, select {
        border: 1px solid $activeFilterColor;
      }
    }

    button.reset_button svg path {
      fill: red;
    }

    label {
      display: block;
      line-height: $topMargin;
      font-size: 14px;
    }

    select {
      width: 100%;
      min-width: 80px;
    }

    a {
      font-weight: bold;
      line-height: 48px;
      text-decoration: underline;
    }
  }

  .search_input {
    width: initial;
    line-height: 19px;
    margin-top: 1px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

}
