@import '../Core/variables.scss';

.emankit__toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  line-height: 32px;
  font-size: 14px;
  color: black;
  padding: 0px 16px;
  border-radius: 16px;
  background-color: $toastBackgroundColor;
  border: 1px solid $toastBorderColor;
  box-shadow: 0 4px 6px 0 rgba(109, 117, 120, 0.16);
  display: inline-block;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
