@import "../Core/variables.scss";

.#{$uikitName}__file_upload_bar {
  padding: 10px;
  padding-left: 40px;
  background: $grey-light;
  position: relative;
  border-radius: 4px;

  &--hasErrors {
    background: rgba($red, .3);
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    word-break: break-word;
    margin-bottom: 10px;
    padding-right: 25px;
  }

  &__size {
    display: inline-block;
    color: #7b7b7c;
  }

  &__remove {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -10px;
    cursor: pointer;

    svg {
      fill: $grey-dark;
    }
  }

  &__icon {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -10px;
  }

  &__indicator {
    margin-top: 15px;
  }
}
