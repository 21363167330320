.emankit__dropdown_menu {
  display: inline-block;

  .popup {
    z-index: 10;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
    display: none;
    flex-direction: column;
    width: 240px;
    visibility: none;
    opacity: 0;
  }

  .hide {
    display: none;
  }

  .dropdown_item {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    text-align: left;
    line-height: 44px;
    padding: 0 12px;

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:hover {
      background-color: $buttonHoverColor;
    }

    &.disabled {
      background-color: $buttonDisabledColor !important;
      cursor: not-allowed;
      color: $buttonFontColor !important;
      border-color: $buttonDisabledColor !important;
    }

    .emankit__icon {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }

    span.label {
      vertical-align: middle
    }
  }
}
