@import '../Core/variables';

.emankit_progress_bar {
  width: 100%;
  border-radius: 5px;
  height: 10px;
  background-color: $verylightblue;
  position: relative;
  overflow: hidden;

  &__indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 100%;
    background-color: $hoverCollapsibleArrowColor;
    transition: width .5s ease;
  }
}
