@import '../Core/variables.scss';

.#{$uikitName}__notification_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 156px;
  max-height: 156px;

  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: $white;
  border-radius: 4px;

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 22px;
    line-height: 22px;

    &__valid {
      font-size: 16px;
      display: inline-block;
      margin-left: $spaceMd;
    }

    &__title {
      display: inline-block;
      cursor: pointer;

      h4 {
        margin-top: 0;
      }
    }
  }


  &__content { 
    font-size: 16px;
  }

  &__footer {
    display: flex;
    max-height: 30px;
    line-height: 30px;
    justify-content: space-between;
    color: $grey-dark;
  }

  &__author {
    text-decoration: none;
    color: $blue;
    font-weight: bold;
  }

  &__actions {
    display: flex;

    &__item {
      margin-right: 8px;
    }
  }
}
