@import '../Core/variables';
@import '../Core/functions';

.emankit__fixed_header {
  position: fixed;
  font-size: $fontSize;
  width: 100%;
  background-color: $background-white;
  z-index: 20;
  line-height: 72px;
  height: 72px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.19);
  left: 0;
  right: 0;
  top: -80px;
  -webkit-transition: top .3s ease;
  overflow: hidden;

  &-enter {
    top: 0px;
  }

  &-enter-done {
    @extend %below-fixed-header;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    color: black;
    padding: 0;
    margin: 0;

    &.error {
      color: $badgeRedBorderColor;
    }
  }
}
