@import '../Core/variables';

.emankit__tabs {
  &__menu {
    position: relative;
    margin: 10px 0 20px;
    display: flex;
    list-style-type: none;
    padding: 0 30px;

    &:before {
      content: "";
      z-index: -1;
      left: 0;
      bottom: 0;
      position: absolute;
      width: 100%;
      border-bottom: 1px solid $verylightblue;
    }

    &__item {
      margin: 0;
      cursor: pointer;
      padding: 15px;
      border: 1px solid transparent;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      &:before {
        content: none;
      }

       &--active {
         border-color: $verylightblue;
         border-bottom-color: $white;
       }
    }
  }

  &__content {
    padding: 15px 0;
  }
}
