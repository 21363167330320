@import '../Core/variables.scss';

.emankit__category_button {
  background-color: $background-white;
  border: 1px solid $verylightblue;
  box-shadow: 0 1px 4px 0 rgba(27, 57, 133, 0.08);
  border-radius: 5px;
  padding: 20px 30px 20px 15px;
  transition: .3s ease background-color;
  position: relative;
  cursor: pointer;

  &:after {
    content: ">";
    font-weight: bold;
    font-size: 20px;
    color: $cobalt;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -10px;
    line-height: 20px;
    transition: .3s ease color;
  }

  &__title {
    color: $cobalt;
    font-weight: bold;
    font-size: 17px;
    transition: .3s ease color;
  }

  &:hover {
    background-color: $cobalt;

    &:after {
      color: $background-white;
    }
  }

  &:hover & {
    &__title {
      color: $background-white;
    }
  }
}
