@import '../Core/variables.scss';

.emankit__indicator {
  display: flex;
  align-items: center;

  position: relative;

  & .indicator-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;

    background: $indicatorNonActiveColor;
    margin: 0px 6px;
  }

  &.active {
    & .indicator-dot {
      background: $indicatorActiveColor;
    }
  }
}
