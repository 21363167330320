@import '../Core/variables.scss';

.emankit__marks {
  border: 1px solid $verylightblue;
  box-shadow: 0 1px 4px 0 $verylightblue-light;
  display: flex;
  flex-flow: row;
  border-radius: 4px;

  &__title {
    display: none;
    color: #7b7b7c;
    font-size: 18px;
    flex-direction: row;
    align-items: center;
  }

  &__marks {
    padding: 20px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    width: 100%;
  }

  &__mark {
    width: 70px;
    height: 50px;
    padding: 0 10px;
    border-right: 1px solid $verylightblue;
    position: relative;
    cursor: pointer;

    &:after {
      content: "";
      width: 60%;
      position: absolute;
      bottom: -20px;
      left: 20%;
      border-bottom: 4px solid transparent;
    }

    &:last-child {
      border-right: none;
    }

    &--active {
      &:after {
        border-bottom-color: $cobalt;
      }
    }
  }

  @media (min-width: $lgBreakpoint) {
    &--withoutLabel & {
      &__title {
        display: none;
      }

      &__marks {
        width: 100%;
      }
    }

    &__title {
      display: flex;
      padding: 30px;
      width: 33.3%;
      border-right: 1px solid $verylightblue;
    }

    &__marks {
      padding: 30px;
      width: 66.6%;
    }

    &__mark {
      width: 100px;
      height: 60px;
      padding: 0 20px;

      &:after {
        bottom: -30px;
      }
    }
  }
}
