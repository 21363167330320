@import '../Core/variables.scss';

.emankit__sort_select_modal {

  padding: 0;
  .emankit__modal__header {
    padding: 32px 32px 0;
    margin-bottom: 24px;
  }
  .emankit__modal__footer {
    border-top: 1px solid $disabledSeparatorBorderColor;
    line-height: normal;
    padding: 16px;
  }

  .content {
    height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .disabled_separator {
    border-top: 1px solid $disabledSeparatorBorderColor;
    margin-top:-1px;
    display: flex;
    flex-direction: column;

    &:empty {
      margin-top: 0;
      border-top: none;
    }
  }

  .modal_item {
    min-height: 40px;
    padding: 0 24px 0 32px;
    display: flex;
    font-size: 15px;
    user-select: none;
    align-items: center;

    .modal_item_content {
      flex-grow: 1;
      display: flex;
      align-items: center;
      margin-right: 10px;
      line-height: 20px;

      .modal_item_checkbox {
        margin-top: 0;
        flex-shrink: 0;
      }

      .modal_item_text {
        margin-left: 12px;
        cursor: pointer;

        &.with_margin {
          margin-left: 36px;
          cursor: initial;
        }
      }
    }

    .modal_item_icon {
      width: 32px;
      padding: 0 7px;
    }

    .modal_item_icon_hide {
      display: none;
    }
  }
}
