.emankit__block_container {

  li {
    display: inline-block;
    margin: 8px 0;
    padding-right: 12px;
    padding-left: 12px;

    @media (min-width: 768px) {
      margin: 12px 0;
    }

    &:before {
      display: none;
    }
  }

  .emankit__block_item {
    position: relative;
    background-color: $background-white;
    border: 1px solid $verylightblue;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(27, 57, 133, 0.08);

    .emankit__icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translate(0, -50%);
      path {
        fill: $cobalt;
      }
    }

    a {
      display: inline-block;
      width: 100%;
      font-size: ($fontSize + 2px);
      font-weight: bold;
      text-decoration: none;
      line-height: 62px;
      padding: 0 22px;
      border-radius: 4px;
      transition: color .15s ease, background-color .15s ease, border .3s ease;
    }
    a:hover {
      background-color: $cobalt;
      color: $background-white;
      & + .emankit__icon {
        path {
          fill: $background-white;
        }
      }
    }
  }
}
