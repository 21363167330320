@import '../Core/variables.scss';
@import './Profile/style.scss';

.#{$uikitName}__header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $white;
  align-items: center;
  z-index: 800; /* Modal overlay got z-index 999 ... */
  height: $headerHeight;
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid $rowLineColor;
  box-shadow: 0 1px 4px 0 rgba(27, 57, 133, 0.16);
  transition: border .3s  ease, box-shadow .3s ease;


  &--unlogged {

  }

  &--withoutCtaButton {

  }

  &__logo {
    background-image: url('../Header/logo.svg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 32px;
    height: 32px;
    margin-right: 32px;
    cursor: pointer;
  }

  &__menu {
    flex-grow: 2;
  }

  &__profile {
    margin-left: 10px;
  }

  &__burger {
    // @TODO remove after responsive implementation
    display: none;
  }

  @media (min-width: $mdBreakpoint) {
    &__burger {
      display: none;
    }
  }

}
