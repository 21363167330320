@import "../Core/variables.scss";

.emankit__uploader {
  width: 100%;

  .dropzone {
    width: 100%;
    border: 2px dashed $uploaderBorderColor;
    background-color: $uploaderColor;
    text-align: center;
    border-radius: 4px;
    padding: 20px;

    .text {
      font-size: 20px;
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
}
