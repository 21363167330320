@import '../Core/variables.scss';
@import './MenuItem/style.scss';

.#{$uikitName}__menu {
  display: flex;
  height: 60px;

  div:first-child {
    margin-left: 0;

    a {
      margin-left: 0;
    }
  }
}
