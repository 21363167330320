@import '../Core/variables.scss';

.emankit__tile {
  display: block;
  padding: 20px;
  border: 1px solid $tileBorderColor;
  box-shadow: 0px 1px 1px $tileShadowColor;
  border-radius: 3px;
  margin: 12px 0;

  &__title {
    font-size: 14px;
    font-weight: normal;
    display: block;
    color: $tileTitleColor;
    margin: 10px 0 4px;
  }

  &__number {
    font-size: 24px;
    display: inline-block;
    font-weight: bold;
    margin: 10px 0;
  }

  &__change {
    display: inline-block;
    margin: 10px 0 10px 10px;
    font-size: 14px;
    color: $tileTextColor;
  }

  &__quantity, &__profit {
    display: block;
    font-size: 14px;
    margin: 5px 0;
    color: $tileTextColor;
  }

  &--link {
    cursor: pointer;
  }

  &--afterLimit {
    background-color: $tileAfterLimitBackgroundColor;
    border-color: $tileAfterLimitBorderColor;
  }

  &--afterLimit & {
    &__number {
      color: $tileAfterLimitColor;
    }
  }

  &--purple & {
    &__number {
      color: $tilePurpleColor;
    }
  }

  &--orange & {
    &__number {
      color: $tileOrangeColor;
    }
  }

  &--green & {
    &__number {
      color: $tileGreenColor;
    }
  }

  &--azure & {
    &__number {
      color: $tileAzureColor;
    }
  }

  &--royal & {
    &__number {
      color: $tileRoyalColor;
    }
  }

  &--scarlet & {
    &__number {
      color: $tileScarletColor;
    }
  }
}
