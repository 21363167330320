@import '../Core/variables';
@import '../Core/functions';

.#{$uikitName}__breadcrumbs {
  padding: $spaceMd 0;
  margin-bottom: $spaceLg;

  &__nav {
    margin: 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
  }

  &__item {
    margin: 0;
    padding-left: 25px;

    position: relative;
    display: inline-block;
    font-weight: normal;
    font-size: $fontSize;

    a {
      color: $black;
      transition: color .3s;

      &:hover {
        text-decoration: none;
      }
    }

    &:before {
      content: '';
      display: inline-block;
      margin: 0;
      padding: 8px;
      width: 8px;
      height: 8px;
      top: 5px;
      left: -8px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjEzIiB2aWV3Qm94PSIwIDAgOCAxMyI+ICAgIDxwYXRoIGZpbGw9IiNDQkNCQ0IiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMS41TDEuNSAwbDYuMDcgNi4wNy02LjA3IDYuMDctMS41LTEuNSA0LjU3LTQuNTd6Ii8+PC9zdmc+);
      background-repeat: no-repeat;
      transform: none;
    }

    &:first-child {
      padding-left: 0;

      &::before {
        padding-left: 0;
        padding-right: 0;
        width: 0;
      }
    }

    &:last-child {
      a {
        text-decoration: none;
        color: $black;
        font-weight: bold;
        cursor: default;
        pointer-events: none;
      }
    }
  }
}
