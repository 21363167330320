@import '../Core/variables.scss';

.emankit__alert {
  position: relative;
  font-size: ($fontSize - 1px);
  line-height: 22px;
  color: $alertFontColor;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: $alertBorderColor;
  background-color: $alertBackgroundColor;
  width: 100%;
  padding: 15px 16px;

  &.with-icon {
    > span::before {
      margin: 0 10px;
    }

    > span {
      display: inline-block;
      width: calc(100% - 22px);
      vertical-align: middle;
      padding: 0 0 0 15px;
    }

    .emankit__icon {
      vertical-align: top;
    }
  }
}
