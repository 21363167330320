.emankit__dateIntervalInputPicker{
  padding: 0 15px;

  &__from {
    padding-left: 0;

    &:after {
      content: "-";
      position: absolute;
      width: 30px;
      right: -15px;
      text-align: center;
      top: 50%;
      height: 20px;
      margin-top: -10px;
    }
  }

  &__to {
    padding-right: 0;
  }

  &__label {
    display: block;
  }

  &--1 {
    .DayPickerInput-Overlay{
      width: 326px;
    }
  }

  &--2 {
    .DayPickerInput-Overlay{
      width: 610px;
    }
  }

  &--2 & {
    &__to {
      .DayPickerInput-Overlay {
        left: calc(-50vw - 15px);
      }
    }
  }

  .DayPicker-Caption select {
    width: auto;
    min-width: auto;
  }

}
