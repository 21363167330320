.progress_indicator_overlay {
  z-index: 1500;
  transition: background .3s;

  &.dark_bg {
    background: $modalDarkOverlayColor;

    .emankit__progress_indicator .emankit__icon {
      path {
        stroke: white !important;
      }
    }
  }

  &.clean_bg {
    background: transparent;
  }

  &.white_bg {
    background: white;
  }

  &.light_bg {
    background: rgba(#fff, 0.5);
  }

  &.local {
    position: absolute;
  }
}

.emankit__progress_indicator {
  margin: auto auto;
  width: 40px;
  height: 40px;
  position: relative;

  &.small {
    width: 32px;
    height: 32px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
}
