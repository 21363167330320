@import '../Core/variables.scss';

.#{$uikitName}__employeecard {
  display: flex;

  &--hasChildren &{
    &__avatar {
      margin-right: $spaceXl;
    }

     &__subtitle {
       margin-bottom: $spaceMd;
     }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $spaceMd;
  }

  &__title {
    margin: 0;
  }

  &__degree {
    display: inline-block;
    font-weight: 100;
    margin-right: $spaceMd;
  }

  &__name {
    display: inline-block;
    margin-right: $spaceMd;
  }

  &__badge {
    display: inline-block;
  }

  &__subtitle {
    display: block;
    color: $grey-dark;
    margin-bottom: 0;
    font-weight: normal;
  }

  &__avatar {
    margin-right: $spaceLg;
  }

}
