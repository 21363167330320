@import '../Core/variables';

.filter_confirm {
  padding: 0 15px;
  min-width: 290px;

  &__element {
    border-bottom: 1px solid $inputBorderColor;
    padding: 15px 0;
  }

  &__buttons {
    padding: 10px 0;
    line-height: 40px;
  }
}
