@import "variables";

.pl-0 {
  padding-left: 0;
}

.pl-32 {
  padding-left: 32px;
}

.pr-0 {
  padding-right: 0;
}

.pr-32 {
  padding-right: 32px;
}

.pt-12 {
  padding-top: 12px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-32 {
  padding-top: 32px;
}

@media (max-width: 1023px) {
  .pt-32 {
    padding-top: 16px;
  }
}

.pt-64 {
  padding-top: 64px;
}

@media (max-width: 1023px) {
  .pt-64 {
    padding-top: 24px;
  }
} 

.pb-24 {
  padding-bottom: 24px;
}

.pl-12 {
  padding-left: 12px;
}

.pr-12 {
  padding-right: 12px;
}

.pb-14 {
  padding-bottom: 14px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pt-24 {
  padding-top: 24px;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-12 {
  margin-left: 12px !important;
}

.mr-12 {
  margin-right: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-63 {
  margin-top: 63px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-80 {
  margin-top: 80px;
}

@media(max-width: 1023px) {
  .mt-80 {
    margin-top: 63px;
  }
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.m-0 {
  margin: 0;
}

.bigger {
  font-size: $fontSize + 2;
  line-height: 27px;
}
.light {
  color: $grey;
}

.bigger-font {
  font-size: calc(#{$fontSize} + 2px);
}

.smaller-font {
  font-size:  calc(#{$fontSize} - 2px);
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}


.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

hr.divider {
  display: block;
  height: 1px;
  width: 60px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
}

.c-cobalt {
  color: $cobalt;
}

.c-black {
  color: $black;
}

.c-grey {
  color: $grey;
}

.c-aqua {
  color: $aqua;
}

.fill-cobalt path {
  fill: $cobalt;
}

.bb-silver {
  border-bottom: 1px solid $silver;
}

.br-silver {
  border-right: 1px solid $silver;
}

@media(max-width: 1023px) {
  .br-silver {
    border-right: 0px;
  }
}

.fw-normal {
  font-weight: normal;
}

.orange-icon {
  display: inline-block;
  width: 26px;
  height: 24px;
  border-radius: 50%;
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  background-color: $orange;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fs-24 {
  font-size: 24px;
}

@media (max-width: 1023px) {
  .fs-24 {
    font-size: 20px;
  }
}

// Capitalize
.cap:first-letter {
  text-transform: capitalize;
}

// Same height flex-items (used in show items)
.equal_height {
  display: flex;
  flex-direction: column;

  & > div {
    flex: 1 0 auto;
  }
}

.align_right {
  text-align: right;
}

.centered-content {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
}

.bg-grey {
  background-color: $grey-light;
}
