@import '../Core/variables.scss';

$topBottomMargin: 14px;
$leftRightMargin: 12px;

.emankit__autocomplete {
  &__suggestions {
    &-container {
      display: none;
      position: absolute;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-color: #ffffff;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
      margin-top: 0;
      flex-direction: column;
      width: 100%;
      padding: 0;
      z-index: 50;

      &-open {
        display: flex;
      }

      li {
        cursor: pointer;
        margin: 0;
        padding: 0;
        line-height: 48px;

        & > span {
          margin: 0 24px;
          display: block;
          color: $grey;
        }
        & span.highlight {
          color: $black;
        }
        &:not(:last-child) > span {
          border-bottom: 1px solid $verylightblue;
        }

        &:before {
          display: none;
        }

        &[aria-selected='true'] {
          color: $black;
          background-color: $badgeLightBlueColor;
        }
      }
    }
    &-list {
      list-style-type: none;
      margin: 0;
      padding: 8px 0;
    }
  }
  &__suggestion {
    padding: $topBottomMargin $leftRightMargin;
    line-height: normal;

    &:hover {
      background-color: #edf0f2;
    }
  }
  &__header {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: $topBottomMargin;
    padding: 0px $leftRightMargin;
  }
  &__delete {
    margin-top: $topBottomMargin;
    padding: 0px $leftRightMargin;
    text-decoration: underline;
  }
}
