@import '../../Core/variables.scss';

.#{$uikitName}__profile {
  position: relative;
  padding: 14px 25px 14px 0;

  &:after {
    content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgMTAgNiI+CiAgICA8cGF0aCBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iIzEzMjkzRCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTkgMUw1IDUgMSAxIiBvcGFjaXR5PSIuNSIvPgo8L3N2Zz4K');
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -10px;
  }

  &:hover & {
    &__submenu {
      display: block;
    }
  }

  &__submenu {
    display: none;
    position: absolute;
    right: 0;
    padding: 0;
    min-width: 200px;
    background: $background-white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(43, 43, 44, 0.16), 0 1px 4px 0 rgba(27, 57, 133, 0.08);
    margin-top: 14px;

    &__item {
      border-bottom: 1px solid $verylightblue-light;

      &:before {
        display: none;
        content: none;
      }

      &:last-child {
        border-bottom: none;
      }

      a {
        color: $grey-dark;
        display: block;
        text-decoration: none;
        padding: $spaceSm $spaceMd;

        &:hover {
          color: $blue;
        }
      }
    }
  }
}
