@import '../Core/variables.scss';

.#{$uikitName}__select {
  display: flex;

  select {
    max-width: 100%;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.05);
    font-weight: 700;
    color: $black;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQwIDQwIj4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSIjMTMyOTNEIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41IiBkPSJNMjAgMThsLTQgNC00LTQiLz4KPC9zdmc+Cg==)
      no-repeat center right;
    background-color: $grey-light;
    border: none;
    cursor: pointer;
    outline: 0;
    flex: 1;
    position: relative;
    -webkit-appearance: none;
    height: 40px;
    max-height: 40px;
  }

  &--white {
    select {
      background-color: $inputBackgroundColor;
    }
  }

  &--grey {
    select {
      background-color: $inputGreyBackgroundColor;

      &:focus {
        background-color: $inputGreyFocusBackgroundColor;
      }
    }
  }

  &--disabled {
    select {
      color: $inputDisabledColor;
      cursor: no-drop;
    }
  }

  label {
    margin-right: 10px;
    text-align: center;
    vertical-align: middle;
    line-height: 36px;
  }
}
