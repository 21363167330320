@import '../Core/variables.scss';
@import '../Core/functions.scss';
@import '../Core/mixins.scss';

.emankit__header_row {
  @include calc-size(min-height, 70px, 90px, $mdBreakpoint, $xxlBreakpoint);
  @include calc-size(padding-top, 10px, 20px, $mdBreakpoint, $xxlBreakpoint);
  @include calc-size(padding-bottom, 10px, 20px, $mdBreakpoint, $xxlBreakpoint);
  display: flex;
  flex-direction: column;
  align-items: center;

  &--fixed {
    @extend %below-fixed-header;
    @include calc-size(padding-left, $spaceMd, $spaceLg, $mdBreakpoint, $xxlBreakpoint);
    @include calc-size(padding-right, $spaceMd, $spaceLg, $mdBreakpoint, $xxlBreakpoint);
    @include calc-size(margin-left, -$spaceMd, -$spaceLg, $mdBreakpoint, $xxlBreakpoint);
    @include calc-size(margin-right, -$spaceMd, -$spaceLg, $mdBreakpoint, $xxlBreakpoint);
    position: -webkit-sticky;
    position: sticky;
    z-index: 5;
    background-color: $background-layout;
  }

  &__title {
    width: 100%;
    flex-grow: 2;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    h1, h2, h3, h4 {
      margin: 0;
      padding: 0;
    }

    .badge_container {
      margin-left: 10px;
    }
  }

  &__button_bar {
    width: 100%;
    flex: 1 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    button {
      margin-right: 12px;
      margin-bottom: 10px;
      transition: color .3s ease;

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: $mdBreakpoint) {
    flex-direction: row;

    &__title {
      width: auto;
      margin-bottom: 0;

      .badge_container {
        margin-left: 15px;
        margin-right: 10px;
      }
    }

    &__button_bar {
      width: auto;
      margin-left: 20px;
      justify-content: flex-end;

      button {
        margin-bottom: 0;
      }
    }

  }
}
