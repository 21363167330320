@import '../Core/variables.scss';

.#{$uikitName}__select_with_search {
  &--white & {
    &__control {
      background-color: $inputBackgroundColor !important;
    }
  }

  &--grey & {
    &__control {
      background-color: $inputGreyBackgroundColor !important;

      &--is-focused {
        background-color: $inputGreyFocusBackgroundColor !important;
      }
    }
  }

  &__control {
    min-height: 40px !important;
    border-radius: 20px !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.05) !important;
    border: none !important;

    &--is-focused {
      border: none;
      box-shadow: 0 1px 0 0 rgba($inputBorderColor, .16) !important;
    }

    &--is-disabled {
      cursor: not-allowed !important;
      pointer-events: auto !important;
    }
  }

  &__indicator {
    color: $black !important;
    padding-right: 15px !important;

    svg {
      width: 18px;
    }
  }

  &__value-container {
    font-weight: bold;
    padding-left: 20px !important;
  }

  &__single-value {
    &--is-disabled {
      color: $inputDisabledColor !important;
      cursor: no-drop !important;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__option {
    &--is-selected {
      background: $blue !important;
    }
  }
}
