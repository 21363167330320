@import '../Core/variables.scss';

textarea {
  resize: none;
}

textarea.#{$uikitName}__input {
  height: 100%;
  min-height: 100px;
  overflow-y: auto;
}
