.#{$uikitName}__input_checkbox {
  cursor: pointer;
  width: 18px;
  height: 18px;
  display: inline-block;
  background-color: white;
  border-radius: 4px;
  position: relative;
  margin-top: 8px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cmVjdCBvcGFjaXR5PSIwLjEiIHdpZHRoPSIyMyIgaGVpZ2h0PSIyMyIgeD0iLjUiIHk9Ii41IiBmaWxsPSIjMTMyOTNkIiByeD0iNCIgLz48L3N2Zz4);
  background-repeat: no-repeat;
  background-size: contain;
  transition: background .1s;

  &--disabled {
    cursor: no-drop;
  }

  &--checked {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiCiAgICB2aWV3Qm94PSI0IDMgMjQgMjQiPgogICAgPGRlZnM+CiAgICAgICAgPHJlY3QgaWQ9ImIiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgcng9IjQiIC8+CiAgICAgICAgPGZpbHRlciBpZD0iYSIgd2lkdGg9IjE1NC4yJSIgaGVpZ2h0PSIxNTQuMiUiIHg9Ii0yNy4xJSIgeT0iLTIyLjklIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPgogICAgICAgICAgICA8ZmVPZmZzZXQgZHk9IjEiIGluPSJTb3VyY2VBbHBoYSIgcmVzdWx0PSJzaGFkb3dPZmZzZXRPdXRlcjEiIC8+CiAgICAgICAgICAgIDxmZUdhdXNzaWFuQmx1ciBpbj0ic2hhZG93T2Zmc2V0T3V0ZXIxIiByZXN1bHQ9InNoYWRvd0JsdXJPdXRlcjEiIHN0ZERldmlhdGlvbj0iMiIgLz4KICAgICAgICAgICAgPGZlQ29sb3JNYXRyaXggaW49InNoYWRvd0JsdXJPdXRlcjEiCiAgICAgICAgICAgICAgICB2YWx1ZXM9IjAgMCAwIDAgMC4xMjE1Njg2MjcgMCAwIDAgMCAwLjIzMTM3MjU0OSAwIDAgMCAwIDAuNTAxOTYwNzg0IDAgMCAwIDAuMDggMCIgLz4KICAgICAgICA8L2ZpbHRlcj4KICAgIDwvZGVmcz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbC1ydWxlPSJub256ZXJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0IDMpIj4KICAgICAgICAgICAgPHVzZSBmaWxsPSIjMDAwIiBmaWx0ZXI9InVybCgjYSkiIHhsaW5rOmhyZWY9IiNiIiAvPgogICAgICAgICAgICA8dXNlIGZpbGw9IiMxMzI5M2QiIHhsaW5rOmhyZWY9IiNiIiAvPgogICAgICAgIDwvZz4KICAgICAgICA8cGF0aCBmaWxsPSIjRkZGIiBkPSJNMTEuNSAxNC40MmwtMS41IDEuNSAzLjk2MSAzLjk2MyA3LjM4Mi03LjM4My0xLjUtMS41LTUuODgyIDUuODgzeiIgLz4KICAgIDwvZz4KPC9zdmc+);
  }

  &:hover {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiCiAgICB2aWV3Qm94PSI0IDMgMjQgMjQiPgogICAgPGRlZnM+CiAgICAgICAgPHJlY3QgaWQ9ImIiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgcng9IjQiIC8+CiAgICAgICAgPGZpbHRlciBpZD0iYSIgd2lkdGg9IjE1NC4yJSIgaGVpZ2h0PSIxNTQuMiUiIHg9Ii0yNy4xJSIgeT0iLTIyLjklIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPgogICAgICAgICAgICA8ZmVPZmZzZXQgZHk9IjEiIGluPSJTb3VyY2VBbHBoYSIgcmVzdWx0PSJzaGFkb3dPZmZzZXRPdXRlcjEiIC8+CiAgICAgICAgICAgIDxmZUdhdXNzaWFuQmx1ciBpbj0ic2hhZG93T2Zmc2V0T3V0ZXIxIiByZXN1bHQ9InNoYWRvd0JsdXJPdXRlcjEiIHN0ZERldmlhdGlvbj0iMiIgLz4KICAgICAgICAgICAgPGZlQ29sb3JNYXRyaXggaW49InNoYWRvd0JsdXJPdXRlcjEiCiAgICAgICAgICAgICAgICB2YWx1ZXM9IjAgMCAwIDAgMC4xMjE1Njg2MjcgMCAwIDAgMCAwLjIzMTM3MjU0OSAwIDAgMCAwIDAuNTAxOTYwNzg0IDAgMCAwIDAuMDggMCIgLz4KICAgICAgICA8L2ZpbHRlcj4KICAgIDwvZGVmcz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbC1ydWxlPSJub256ZXJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0IDMpIj4KICAgICAgICAgICAgPHVzZSBmaWxsPSIjMDAwIiBmaWx0ZXI9InVybCgjYSkiIHhsaW5rOmhyZWY9IiNiIiAvPgogICAgICAgICAgICA8dXNlIGZpbGw9IiMxMzI5M2QiIHhsaW5rOmhyZWY9IiNiIiAvPgogICAgICAgIDwvZz4KICAgICAgICA8cGF0aCBvcGFjaXR5PSIwLjUiIGZpbGw9IiNGRkYiIGQ9Ik0xMS41IDE0LjQybC0xLjUgMS41IDMuOTYxIDMuOTYzIDcuMzgyLTcuMzgzLTEuNS0xLjUtNS44ODIgNS44ODN6IiAvPgogICAgPC9nPgo8L3N2Zz4=);
  }

  &--partial {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiCiAgICB2aWV3Qm94PSI0IDMgMjQgMjQiPgogICAgPGRlZnM+CiAgICAgICAgPHJlY3QgaWQ9ImIiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgcng9IjQiIC8+CiAgICAgICAgPGZpbHRlciBpZD0iYSIgd2lkdGg9IjE1NC4yJSIgaGVpZ2h0PSIxNTQuMiUiIHg9Ii0yNy4xJSIgeT0iLTIyLjklIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPgogICAgICAgICAgICA8ZmVPZmZzZXQgZHk9IjEiIGluPSJTb3VyY2VBbHBoYSIgcmVzdWx0PSJzaGFkb3dPZmZzZXRPdXRlcjEiIC8+CiAgICAgICAgICAgIDxmZUdhdXNzaWFuQmx1ciBpbj0ic2hhZG93T2Zmc2V0T3V0ZXIxIiByZXN1bHQ9InNoYWRvd0JsdXJPdXRlcjEiIHN0ZERldmlhdGlvbj0iMiIgLz4KICAgICAgICAgICAgPGZlQ29sb3JNYXRyaXggaW49InNoYWRvd0JsdXJPdXRlcjEiCiAgICAgICAgICAgICAgICB2YWx1ZXM9IjAgMCAwIDAgMC4xMjE1Njg2MjcgMCAwIDAgMCAwLjIzMTM3MjU0OSAwIDAgMCAwIDAuNTAxOTYwNzg0IDAgMCAwIDAuMDggMCIgLz4KICAgICAgICA8L2ZpbHRlcj4KICAgIDwvZGVmcz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNCAzKSI+CiAgICAgICAgICAgIDx1c2UgZmlsbD0iIzAwMCIgZmlsdGVyPSJ1cmwoI2EpIiB4bGluazpocmVmPSIjYiIgLz4KICAgICAgICAgICAgPHVzZSBmaWxsPSIjMTMyOTNkIiB4bGluazpocmVmPSIjYiIgLz4KICAgICAgICA8L2c+CiAgICAgICAgPHBhdGggZmlsbD0iI0ZGRiIgZD0iTTEwIDE0aDEydjIuMTJIMTB6IiAvPgogICAgPC9nPgo8L3N2Zz4=);
  }

  &__label {
    display: inline-block;
    padding-left: 32px;
    position: relative;
    line-height: 18px;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    label {
      cursor: pointer;
    }

    &--disabled {
      opacity: .5;
      label {
        cursor: no-drop;
      }

      .#{$uikitName}__input_checkbox {
        cursor: no-drop;
        filter: grayscale(100);
      }
    }
  }

  &__label & {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
  }
}

