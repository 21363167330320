@import "../Core/variables";
@import '../../node_modules/react-day-picker/lib/style';

.#{$uikitName}__input_datepicker {
  @extend .#{$uikitName}__input;

  &--white {
    @extend .#{$uikitName}__input--white;
  }

  &--grey {
    @extend .#{$uikitName}__input--grey;
  }
}

.DayPickerInput-Overlay {
  top: 5px;
  border-radius: 4px;
  z-index: 5;
  box-shadow: 0 4px 16px 0 rgba(43, 43, 44, 0.16), 0 1px 4px 0 rgba(27, 57, 133, 0.08) !important;

  &:focus, & *:focus {
    outline: none;
  }
}

.DayPickerInput {
  width: 100%;
}

.DayPicker {
  padding: 16px;
}

.DayPicker-wrapper {
  padding-bottom: 0 !important;
}

.DayPicker-NavBar {

  .DayPicker-NavButton {
    @extend .#{$uikitName}__button;
    position: absolute;
    top: 0;
    margin: 0;

    &:hover {
      opacity: 1;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -7.5px;
      margin-left: -4px;
    }

    &.DayPicker-NavButton--prev {
      right: unset !important;
      left: 0 !important;

      &:before {
        content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjEzIiB2aWV3Qm94PSIwIDAgOCAxMyI+ICAgIDxwYXRoIGZpbGw9IiNGRkZGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTcuODU1IDIuMjE1bC0xLjUtMS41LTYuMDcgNi4wNyA2LjA3IDYuMDcgMS41LTEuNS00LjU3LTQuNTd6Ii8+PC9zdmc+) !important;

      }
    }
    &.DayPicker-NavButton--next {
      left: unset !important;
      right: 0 !important;

      &:before {
        content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjEzIiB2aWV3Qm94PSIwIDAgOCAxMyI+ICAgIDxwYXRoIGZpbGw9IiNGRkZGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTS4yODUgMi4yMTVsMS41LTEuNSA2LjA3IDYuMDctNi4wNyA2LjA3LTEuNS0xLjUgNC41Ny00LjU3eiIvPjwvc3ZnPg==) !important;

      }
    }
  }
}

.DayPicker-Month {
  margin: 0 !important;
}

.DayPicker-Months {
  margin: 0 40px;
}

.DayPicker-TodayButton{
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 rgba($cobalt, 0.08);
  background: $buttonNormal;
  border-style: none;
  border-width: 0;
  color: $buttonFontColor;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  outline: 0;
  padding: 8px 20px;
  display: block;
  margin: auto;
}

.DayPicker-Week {
  .DayPicker-Day--selected.DayPicker-Day--outside:not(.DayPicker-Day--disabled){
    &:hover {
      background-color: $cobalt-light;
      color: $cobalt;
      font-weight: bold;
    }
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background: $buttonNormal;
    &:hover {
      background: $buttonHoverColor;
      font-weight: bold;
    }
  }
  .DayPicker-Day--outside {
    color: rgba( $grey, .56 );
    font-weight: normal !important;
  }

  .DayPicker-Day--today {
    font-weight: bold !important;
    background: $grey-light;
    border-radius: 50%;
  }
  .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: $blue;
  }
  .DayPicker-Day--today.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    color: $white !important;
  }
}
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Week .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
  background-color: $grey !important;
  color: $blue;
  font-weight: bold;
  cursor: pointer;
}


.DayPicker-Day, .DayPicker-Weekdays, .DayPicker-Caption, .DayPicker-Footer, .DayPickerInput-Overlay {
  line-height: initial;
}

.emankit__collapsible_container .header .DayPickerInput-Overlay {
  top: -20px;
}

.DayPicker-Caption {
  text-align: center !important;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-bottom: 8px !important;
  @extend .#{$uikitName}__select;
  display: table-caption !important;

  select {
    min-width: 100px;

    &:first-child {
      margin-right: 10px;
    }
  }
}

.DayPicker-Weekday, .DayPicker-Day {
  width: 40px;
  height: 40px;
  padding: 10px 11px !important;
  vertical-align: middle;
  font-size: ($fontSize - 1px) !important;
}
.DayPicker-Weekday {
  color: $black !important;
  font-weight: 700;
}

.DayPicker-Day {
  font-weight: normal;
  color: $black;
  border-radius: 20px;
}
.DayPicker-Day:not(.DayPicker-Day--selected) {
  transition: background-color .15s ease;
}

.DayPicker-wrapper .DayPicker-Footer {
  padding-top: 16px;
}

.DayPicker-Footer-Custom {
  margin-left: -16px;
  margin-right: -16px;
  border-top: 1px solid rgba($silver, .5);
  padding: 16px 16px 0;

  display: flex;
  justify-content: space-between;

  a {
    line-height: 38px;
    font-weight: bold;
  }

  .DayPicker-Range-Label {
    margin-left: auto;
    margin-right: 24px;
    font-size: ($fontSize - 1px);
    font-weight: normal;
    line-height: 38px;
    color: $grey;
  }
}
