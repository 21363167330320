@import '../Core/variables.scss';

.emankit__tileColumn {
  &__title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 4px solid;
  }

  &--purple & {
    &__title {
      border-color: $tilePurpleColor;
    }
  }

  &--orange & {
    &__title {
      border-color: $tileOrangeColor;
    }
  }

  &--green & {
    &__title {
      border-color: $tileGreenColor;
    }
  }

  &--azure & {
    &__title {
      border-color: $tileAzureColor;
    }
  }

  &--royal & {
    &__title {
      border-color: $tileRoyalColor;
    }
  }

  &--scarlet & {
    &__title {
      border-color: $tileScarletColor;
    }
  }

}
