@import '../Core/variables';
@import '../Core/functions';

.#{$uikitName}__fixed_footer {
  display: flex;
  align-items: center;
  position: fixed;
  font-size: $fontSize;
  width: 100%;
  background-color: $background-grey;
  z-index: 20;
  height: 70px;
  box-shadow: 0 1px 4px 0 rgba(27, 57, 133, 0.16);
  left: 0;
  right: 0;
  bottom: -70px;
  transition: bottom .3s ease;
  overflow: hidden;
  padding: $spaceMd;
  border-top: 1px solid $sidebarBorderColor;

  &-enter {
    bottom: 0px;
  }

  &-enter-done {
    bottom: 0px;
  }

  &__container {
    width: 100%;
    line-height: 40px;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    color: black;
    padding: 0;
    margin: 0;

    &.error {
      color: $badgeRedBorderColor;
    }
  }
}
