@import '../Core/variables.scss';
@import '../Core/mixins.scss';

.emankit__panel {
  position: relative;
  width: 100%;
  background-color: $panelColor;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  @include calc-size(padding, 24px, 32px, $smBreakpoint, $lgBreakpoint);
  @include calc-size(margin-top, 10px, 16px, $smBreakpoint, $lgBreakpoint);
  @include calc-size(margin-bottom, 10px, 16px, $smBreakpoint, $lgBreakpoint);

  &.nomargin {
    margin: 0;
  }

  &__title {
    display: block;
    font-weight: bold;
    @include calc-size(margin-bottom, 10px, 15px, $smBreakpoint, $lgBreakpoint);
    @include calc-size(font-size, 18px, 20px, $smBreakpoint, $lgBreakpoint);
  }

  &.infobox {
    .col-form-label {
      color: $infoThColor;
      padding-right: 0;
      padding-left: 0;
    }

    .form-group {
      margin-bottom: 0;
    }

    &.with-form {
      .form-group {
        margin-bottom: 10px;
      }
    }
  }
}
