.address_picker {
  position: relative;

  &__map {
    width: 100%;
    height: 60vw;
    max-height: 80vh;
  }

  &__footer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
  }

  &__confirm {
  }

  &__address {
    height: 50px;
    width: calc(100% - 200px);

    span {
      display: block;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 18px;
    }
  }

  &__autocomplete {
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 10px;
    width: 300px;
  }

}
