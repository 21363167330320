@import '../Core/variables.scss';
@import '../Core/mixins.scss';

.emankit__collapsible_container {
  border-bottom: 1px solid $rowLineColor;

  &__header {
    padding: 10px 0;
    align-items: center;
  }

  &__children {
    padding-bottom: 20px;
    position: relative;
  }

  &__title {

    .header_clickable {
      cursor: pointer;
      display: flex;
      align-items: center;

      h2 {
        display: inline-block;
        margin: 0;
        padding: 10px 0;
        @include calc-size(font-size, 16px, 18px, $smBreakpoint, $lgBreakpoint);
        color: $fontCollapsibleColor;
      }

      svg {
        @include calc-size(width, 25px, 32px, $smBreakpoint, $lgBreakpoint);
        @include calc-size(height, 25px, 32px, $smBreakpoint, $lgBreakpoint);
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;

    > * {
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 5px;
      margin-right: 5px;
    }

  }

  &--error &{
    &__header .header_clickable {
      svg path {
        fill: $fontCollapsibleErrorColor;
      }

      h2 {
        color: $fontCollapsibleErrorColor;
      }
    }
  }

  &--closed &{

    &__buttons {
      display: none;
    }

    &__children {
      display: none;
    }

    &__header {

      .header_clickable {

        &:hover {
          background-color: $hoverCollapsibleColor;

          svg path {
            fill: $hoverCollapsibleArrowColor;
          }
        }
      }
    }
  }

  &--loading {
    .emankit__progress_indicator {
      .emankit__icon {
        width: 40px;
        height: 40px;
      }
    }
  }

  @media (min-width: $mdBreakpoint) {
    &__title {
      .header_clickable {
        min-height: 50px;
      }
    }

    &__buttons {
      align-items: center;
      justify-content: flex-end;
    }
  }
}
