@import '../Core/variables.scss';

.#{$uikitName}__vertical_menu {
  display: block;

  &__list {
    padding: 0;
    margin: 0;
  }

  &__item {

    &:before {
      content: '';
      display: none;
    }

    > * {
      padding: $spaceSm 0;
      display: block;
      text-decoration: none;
      color: $menuButtonColor;

      &.active {
        color: $linkColor;
        font-weight: bold;
      }
    }
  }
}
