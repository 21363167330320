@import '../Core/variables.scss';

.emankit__pagination {
  position: relative;

  .buttons {
    text-align: right;
    position: static;
    @media (min-width: 1024px) {
      position: relative;
    }

    .stats {
      font-size: ($fontSize - 1px);
      line-height: 40px;
      text-align: center;

      width: 100%;
      position: absolute;
      left: 0;
      z-index: 0;

      @media (min-width: 1024px) {
        margin-right: 24px;
        position: static;
      }
    }
  }

  .pages {
    font-weight: normal;
    font-size: ($fontSize - 1px);

    position: static;
    @media (min-width: 1024px) {
      position: relative;
    }

    .select {
      @extend .#{$uikitName}__select;
      width: 80px;
      display: inline-flex !important;
      
      @media (max-width: 1024px) {
        display: none !important;
      }
    }

    .label {
      display: none;
      @media (min-width: 1024px) {
        margin-left: 12px;
        display: inline-block;
      }
    }
  }

  button {
    &.page {
      display: none;
      @media (min-width: 1024px) {
        display: inline-block;
      }

      &:not(:hover) {
        transition: none;
      }
      &:not(.active) {
        background-color: inherit;
        box-shadow: none;
      }
    }

    &:last-child {
      margin-right: 0px;
    }

    &.previous_button {
      margin-right: 24px;
      & > .emankit__icon {
        left: 7px;
      }

      position: absolute;
      left: 0;

      @media (min-width: 1024px) {
        position: relative;
        left: auto;
      }
    }

    &.next_button {
      margin-left: 24px;
      & > .emankit__icon {
        left: auto;
        right: 7px;
      }
    }

    &.page {
      min-width: 40px;
      padding: 0 4px;
    }
    .emankit__icon {
      width: 24px;
      height: 24px;
      margin-top: -2px;
      transform: none;
    }
  }

  .gap {
    width: 24px;
    text-align: center;
    line-height: 40px;
    user-select: none;
    color: $silver;
    display: none;
    @media (min-width: 1024px) {
      display: inline-block;
    }
  }
}
