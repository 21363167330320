@import '../Core/variables.scss';

.emankit__filter_bar__item {
  position: relative;

  label {
    color: $grey-dark;
  }

  .fake_input {
    border-radius: 20px;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.05);
    color: $black;
    line-height: 40px;
    height: 40px;
    padding: 0 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 240px;
    background-color: $white;
    cursor: pointer;

    &--withIcon {
      padding-right: 30px;
    }

    svg {
      position: absolute;
      right: 12px;
      top: 38px;
    }

    .placeholder {
      color: $inputPlaceholderColor;
    }
  }

  .input_container {
    min-width: 180px;
  }

  .dropdown {
    background-color: white;
    position: absolute;
    min-width: 220px;
    max-width: 400px;
    z-index: 300;
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);

    input[type=text] {
      @extend .#{$uikitName}__input;
      @extend .#{$uikitName}__input--grey;
      width: 200px !important;
      margin: 10px;
      outline: 0;
    }

    label {
      display: block;
      margin: 5px 10px 0;
    }

    hr {
      margin: 0;
      padding: 0;
      border: 0;
      border-bottom: 1px solid #c6cacc;
    }

    .inputs {
      height: 210px;
      overflow: auto;

      & > strong {
        line-height: 33px;
        padding-left: 24px;
      }

      label {
        display: flex;
        align-items: center;
        min-height: 32px;
        cursor: pointer;
        padding-left: 10px;

        &.option {
          padding-left: 25px;
          font-weight: normal;
          display: flex;
          flex-flow: row;
          align-items: baseline;

          .text {
            display: block;
            white-space: normal;
            max-width: 350px;
          }
        }

        input { margin-right: 10px; }

        &:hover {
          background-color: #edf0f2;
        }
      }
    }

    .buttons {
      text-align: right;
      padding: 10px;
    }
  }
}
