.emankit__toolbar {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 12px;

    &:last-child {
      margin-right: inherit;
    }
  }
}
